import { lazy } from 'react';
import {
  ASSETS_BATTERY_POSSESSIONS_PATH,
  ASSETS_BATTERY_INVENTORY_PATH,
  ASSETS_SMART_CARTS_PATH,
  ASSETS_PAGE,
  ASSETS_PATH,
  DASHBOARD_PAGE,
  DASHBOARD_PATH,
  EARNINGS_PAGE,
  EARNINGS_PATH,
  EMPLOYEE_MANAGEMENT_PAGE_ADD,
  EMPLOYEE_MANAGEMENT_PAGE_EDIT,
  EMPLOYEE_MANAGEMENT_PAGE,
  EMPLOYEE_MANAGEMENT_PATH_ADD,
  EMPLOYEE_MANAGEMENT_PATH_EDIT,
  EMPLOYEE_MANAGEMENT_PATH,
  LIVE_MAP_PAGE,
  LIVE_MAP_PATH,
  LOGIN_IMPERSONATE_PATH,
  LOGIN_PAGE,
  LOGIN_PATH,
  MY_TASKS_PAGE,
  MY_TASKS_PATH,
  MY_TEAM_PAGE,
  MY_TEAM_PATH,
  PAGE_VISIT_EVENT,
  PERFORMANCE_PAGE,
  PERFORMANCE_PATH,
  PROFILE_PAGE,
  PROFILE_PATH,
  SETTINGS_PAGE,
  SETTINGS_PATH,
  TASK_FORECAST_PAGE,
  TASK_FORECAST_PATH,
  UNKNOWN_PAGE,
  ASSETS_BATTERY_DETAIL,
  ASSETS_SMART_CARTS_DETAIL,
} from 'constants/amplitude';
import { BASE_PATH } from 'constants/app';
import { getFlattenedRoutesList } from 'lib/routes';
import PageNotFound from 'pages/404';
import { RoutesNested, RoutesFlattened } from 'types/global.d';

const DEFAULT_RETRIES = 3;
const RETRIES_KEY = 'lazy_retries';

const lazyErrorHandler = (error): React.FC => {
  const retries = Math.min(
    Number(sessionStorage.getItem(RETRIES_KEY) ?? DEFAULT_RETRIES),
    DEFAULT_RETRIES,
  );

  if (retries > 0) {
    sessionStorage.setItem(RETRIES_KEY, (retries - 1).toString());
    window.location.reload();
    return () => null;
  }

  sessionStorage.removeItem(RETRIES_KEY);
  throw error;
};

const lazyWithRetries = (
  importResult: Promise<{
    default: React.ComponentType<unknown>;
  }>,
) =>
  lazy(() =>
    importResult.catch((error) => ({
      default: lazyErrorHandler(error),
    })),
  );

const Dashboard = lazyWithRetries(import('pages/Dashboard'));
const AssetPage = lazyWithRetries(import('pages/Assets/AssetsPage'));
const BatteryInventoryPage = lazyWithRetries(
  import('pages/Assets/BatteryInventoryPage'),
);
const BatteryDetailPage = lazyWithRetries(
  import('pages/Assets/components/BatteryDetail/components/BatteryDetailPage'),
);
const SmartCartsDetailPage = lazyWithRetries(
  import(
    'pages/Assets/components/SmartCartsDetail/components/SmartCartsDetailPage'
  ),
);
const Earnings = lazyWithRetries(import('pages/Earnings'));
const EmployeeManagement = lazyWithRetries(import('pages/EmployeeManagement'));

// TODO: Convert this to a function component so we don't have to cast the type
const EmployeeManagementForm = lazyWithRetries(
  import('pages/EmployeeManagement/components/EmployeesForm') as Promise<{
    default: React.ComponentType<unknown>;
  }>,
);
const HomePage = lazyWithRetries(import('pages/HomePage'));
const Performance = lazyWithRetries(import('pages/Performance'));
const LiveMap = lazyWithRetries(import('pages/LiveMap'));
const Login = lazyWithRetries(import('pages/Login'));
const MyTasks = lazyWithRetries(import('pages/MyTasks'));
const MyTeam = lazyWithRetries(import('pages/MyTeam'));
const Profile = lazyWithRetries(import('pages/Profile'));
const Settings = lazyWithRetries(import('pages/Settings'));
const TaskForecast = lazyWithRetries(import('pages/TaskForecast'));

export const routes: RoutesNested = {
  label: 'LP Portal',
  path: BASE_PATH,
  component: HomePage,
  amplitudeEvent: PAGE_VISIT_EVENT,
  subsections: [
    {
      label: 'LP Portal',
      path: '/',
      component: HomePage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: LOGIN_PAGE,
      path: LOGIN_PATH,
      component: Login,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: LOGIN_PAGE,
      path: LOGIN_IMPERSONATE_PATH,
      component: Login,
      amplitudeEvent: `Impersonate ${PAGE_VISIT_EVENT}`,
    },
    {
      label: DASHBOARD_PAGE,
      path: DASHBOARD_PATH,
      component: Dashboard,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_PATH,
      component: AssetPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_BATTERY_POSSESSIONS_PATH,
      component: AssetPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_BATTERY_INVENTORY_PATH,
      component: BatteryInventoryPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_SMART_CARTS_DETAIL,
      component: SmartCartsDetailPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_SMART_CARTS_PATH,
      component: AssetPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: ASSETS_PAGE,
      path: ASSETS_BATTERY_DETAIL,
      component: BatteryDetailPage,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: TASK_FORECAST_PAGE,
      path: TASK_FORECAST_PATH,
      component: TaskForecast,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: PERFORMANCE_PAGE,
      path: PERFORMANCE_PATH,
      component: Performance,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: EARNINGS_PAGE,
      path: EARNINGS_PATH,
      component: Earnings,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: LIVE_MAP_PAGE,
      path: LIVE_MAP_PATH,
      component: LiveMap,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: MY_TASKS_PAGE,
      path: MY_TASKS_PATH,
      component: MyTasks,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: MY_TEAM_PAGE,
      path: MY_TEAM_PATH,
      component: MyTeam,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: EMPLOYEE_MANAGEMENT_PAGE,
      path: EMPLOYEE_MANAGEMENT_PATH,
      component: EmployeeManagement,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: EMPLOYEE_MANAGEMENT_PAGE_ADD,
      path: EMPLOYEE_MANAGEMENT_PATH_ADD,
      component: EmployeeManagementForm,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: EMPLOYEE_MANAGEMENT_PAGE_EDIT,
      path: EMPLOYEE_MANAGEMENT_PATH_EDIT,
      component: EmployeeManagementForm,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: PROFILE_PAGE,
      path: PROFILE_PATH,
      component: Profile,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: SETTINGS_PAGE,
      path: SETTINGS_PATH,
      component: Settings,
      amplitudeEvent: PAGE_VISIT_EVENT,
    },
    {
      label: UNKNOWN_PAGE,
      path: '*',
      component: PageNotFound,
      amplitudeEvent: PAGE_VISIT_EVENT,
      status: 404,
    },
  ],
};

// Concat the path for nested routes
export const flattenedRoutesList: RoutesFlattened =
  getFlattenedRoutesList(routes);
