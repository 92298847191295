import palette from 'components/theme/palette';

export const defaultColor = palette.grey[800];
export const defaultNavbarColor = palette.grey[600];

export type VariantTypes =
  // Common Icons
  | 'batteryHalf'
  | 'batteryFull'
  | 'download'
  | 'eye'
  | 'eyeOff'
  | 'filter'
  | 'lime'
  | 'refresh'
  // Form Icons
  | 'assign'
  | 'assigned'
  | 'call'
  | 'email'
  | 'password'
  | 'percentage'
  // Earnings Icons
  | 'history'
  | 'income'
  | 'loading'
  | 'location'
  // Navbar Icons
  | 'analytics'
  | 'authenticator'
  | 'booking'
  | 'calendar'
  | 'charts'
  | 'copy'
  | 'dashboard'
  | 'earnings'
  | 'employeeManagement'
  | 'error'
  | 'kanban'
  | 'language'
  | 'map'
  | 'settings'
  | 'team'
  | 'trophy'
  | 'upload'
  | 'user'
  // Task Icons
  | 'charge'
  | 'chargeWithColor'
  | 'deploy'
  | 'deployWithColor'
  | 'diagnose'
  | 'diagnoseWithColor'
  | 'move'
  | 'moveWithColor'
  | 'retrieve'
  | 'retrieveWithColor'
  | 'service'
  | 'serviceWithColor'
  | 'swap'
  | 'swapWithColor'
  // Vehicle Icons
  | 'bus'
  | 'car'
  | 'ebike'
  | 'moped'
  | 'scooter'
  | 'scooterSeated'
  | 'train'
  | 'truck'
  | 'walk'
  // Task Vehicle Icons
  | 'taskVehicleChargeEbike'
  | 'taskVehicleChargeMoped'
  | 'taskVehicleChargeScooter'
  | 'taskVehicleDiagnoseEbike'
  | 'taskVehicleDiagnoseMoped'
  | 'taskVehicleDiagnoseScooter'
  | 'taskVehicleMoveEbike'
  | 'taskVehicleMoveMoped'
  | 'taskVehicleMoveScooter'
  | 'taskVehicleRetrieveEbike'
  | 'taskVehicleRetrieveMoped'
  | 'taskVehicleRetrieveScooter'
  | 'taskVehicleServiceEbike'
  | 'taskVehicleServiceMoped'
  | 'taskVehicleServiceScooter'
  | 'taskVehicleSwapEbike'
  | 'taskVehicleSwapMoped'
  | 'taskVehicleSwapScooter'
  // Map - Density Pin Icons
  | 'pinCharge'
  | 'pinDeploy'
  | 'pinDiagnose'
  | 'pinHotspot'
  | 'pinMove'
  | 'pinRetrieve'
  | 'pinService'
  | 'pinSwap'
  | 'pinMultitask'
  // Map - Task Badge Icons
  | 'taskBadgeChargeEbike'
  | 'taskBadgeChargeMoped'
  | 'taskBadgeChargeScooter'
  | 'taskBadgeChargeScooterSeated'
  | 'taskBadgeDiagnoseEbike'
  | 'taskBadgeDiagnoseMoped'
  | 'taskBadgeDiagnoseScooter'
  | 'taskBadgeDiagnoseScooterSeated'
  | 'taskBadgeMoveEbike'
  | 'taskBadgeMoveMoped'
  | 'taskBadgeMoveScooter'
  | 'taskBadgeMoveScooterSeated'
  | 'taskBadgeRetrieveEbike'
  | 'taskBadgeRetrieveMoped'
  | 'taskBadgeRetrieveScooter'
  | 'taskBadgeRetrieveScooterSeated'
  | 'taskBadgeServiceEbike'
  | 'taskBadgeServiceMoped'
  | 'taskBadgeServiceScooter'
  | 'taskBadgeServiceScooterSeated'
  | 'taskBadgeSwapEbike'
  | 'taskBadgeSwapMoped'
  | 'taskBadgeSwapScooter'
  | 'taskBadgeSwapScooterSeated'
  | 'taskBadgeMultitaskEbike'
  | 'taskBadgeMultitaskMoped'
  | 'taskBadgeMultitaskScooter'
  | 'taskBadgeMultitaskScooterSeated'
  // Map - Task Pin Icons
  | 'taskPinChargeEbike'
  | 'taskPinChargeMoped'
  | 'taskPinChargeScooter'
  | 'taskPinChargeScooterSeated'
  | 'taskPinChargeEbikeHighest'
  | 'taskPinChargeMopedHighest'
  | 'taskPinChargeScooterHighest'
  | 'taskPinChargeScooterSeatedHighest'
  | 'taskPinDiagnoseEbike'
  | 'taskPinDiagnoseMoped'
  | 'taskPinDiagnoseScooter'
  | 'taskPinDiagnoseScooterSeated'
  | 'taskPinDiagnoseEbikeHighest'
  | 'taskPinDiagnoseMopedHighest'
  | 'taskPinDiagnoseScooterHighest'
  | 'taskPinDiagnoseScooterSeatedHighest'
  | 'taskPinMoveEbike'
  | 'taskPinMoveMoped'
  | 'taskPinMoveScooter'
  | 'taskPinMoveScooterSeated'
  | 'taskPinMoveEbikeHighest'
  | 'taskPinMoveMopedHighest'
  | 'taskPinMoveScooterHighest'
  | 'taskPinMoveScooterSeatedHighest'
  | 'taskPinRetrieveEbike'
  | 'taskPinRetrieveMoped'
  | 'taskPinRetrieveScooter'
  | 'taskPinRetrieveScooterSeated'
  | 'taskPinRetrieveEbikeHighest'
  | 'taskPinRetrieveMopedHighest'
  | 'taskPinRetrieveScooterHighest'
  | 'taskPinRetrieveScooterSeatedHighest'
  | 'taskPinServiceEbike'
  | 'taskPinServiceMoped'
  | 'taskPinServiceScooter'
  | 'taskPinServiceScooterSeated'
  | 'taskPinServiceEbikeHighest'
  | 'taskPinServiceMopedHighest'
  | 'taskPinServiceScooterHighest'
  | 'taskPinServiceScooterSeatedHighest'
  | 'taskPinSwapEbike'
  | 'taskPinSwapMoped'
  | 'taskPinSwapScooter'
  | 'taskPinSwapScooterSeated'
  | 'taskPinSwapEbikeHighest'
  | 'taskPinSwapMopedHighest'
  | 'taskPinSwapScooterHighest'
  | 'taskPinSwapScooterSeatedHighest';

const svgViewBox = {
  densityPin: '0 0 12 12',
  general: '0 0 24 24',
  taskColor: '0 0 32 32',
  taskBadge: '0 0 28 28',
  taskPin: '20 16 32 36',
};

export const getViewBox = (variant: VariantTypes): string => {
  const variantStr = variant as string;

  if (variantStr.startsWith('taskBadge')) {
    return svgViewBox.taskBadge;
  }

  if (variantStr.startsWith('taskPin')) {
    return svgViewBox.taskPin;
  }

  if (
    variantStr.startsWith('taskVehicle') ||
    variantStr.endsWith('WithColor')
  ) {
    return svgViewBox.taskColor;
  }

  if (variant.startsWith('pin')) {
    return svgViewBox.densityPin;
  }

  return svgViewBox.general;
};
