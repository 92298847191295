export const FILTERS_MAP = {
  priorityType: {
    highest: 'Highest',
    high: 'High',
    medium: 'Medium',
    low: 'Low',
  },
  taskType: {
    BatterySwapTask: 'Swap',
    ScooterChargeTask: 'Charge',
    VehicleRetrievalTask: 'Retrieve',
    VehicleRebalanceTask: 'Move',
    VehicleDeployTask: 'Deploy',
    OnsiteRepairTask: 'Service',
    Multitask: 'Multitask',
  },
  vehicleType: {
    scooter: 'Scooter',
    electric: 'E-Bike',
  },
};

// should sync with `POSSIBLE_CSV_COLUMNS`  under LogisticsPartner::PayableItemsCsv
// https://github.com/limebike/limebike-web/pull/16327/files
export const CSV_DOWNLOAD_COLUMNS = [
  {
    id: 'task_type',
    displayName: 'Task Type',
  },
  {
    id: 'created_at',
    displayName: 'Creation Time',
  },
  {
    id: 'current_status',
    displayName: 'Current Status', // task status
  },
  {
    id: 'earnings',
    displayName: 'Earnings',
  },
  {
    id: 'vehicle_type',
    displayName: 'Vehicle Type',
  },
  {
    id: 'waiting_time',
    displayName: 'Waiting Time',
  },
  {
    id: 'service_time',
    displayName: 'Service Time',
  },
  {
    id: 'potential_earnings',
    displayName: 'Potential Earnings',
  },
  {
    id: 'plate_number',
    displayName: 'Plate No.',
  },
  {
    id: 'started_at',
    displayName: 'Start Time',
  },
  {
    id: 'assignee',
    displayName: 'Assignee',
  },
  {
    id: 'payout_date',
    displayName: 'Payout Date',
  },
  {
    id: 'prioirty',
    displayName: 'Priority',
  },
  {
    id: 'ended_at',
    displayName: 'Complete Time',
  },
  {
    id: 'penalties',
    displayName: 'Penalties',
  },
  {
    id: 'payout_description',
    displayName: 'Payout Description',
  },
  {
    id: 'day_earnings_applied',
    displayName: 'Earnings Applied Date',
  },
  {
    id: 'collector',
    displayName: 'Collector',
  },
  {
    id: 'deployer',
    displayName: 'Deployer',
  },
  {
    id: 'payable_item_id',
    displayName: 'Payable Item Id',
  },
  {
    id: 'region',
    displayName: 'Region',
  },
  {
    id: 'multitask?',
    displayName: 'Multi-tasked',
  },
];

export const DOWNLOAD_ALL_TASKS_TOOLTIP =
  'Download all tasks completed in the exact date range, from 0:00am to 23:59pm.';
export const DOWNLOAD_TASKS_BY_PAYOUT_TOOLTIP = `Download all tasks based on Hyperwallet payout cycles. (e.g. 8/29 to 8/30 
  include tasks marked for payout on 8/29 and 8/30)`;

export const DEFAULT_REGION_NAME = 'San Francisco';
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const REGEX_UNDERSCORE = /_/g;
export const DEFAULT_MIN_START_DATE = '2017/01/01';
export const FUTURE_DATE_ERROR = 'Date cannot be after today';
export const PAST_DATE_ERROR = 'Date cannot be before today';
export const MAX_DATE_ERROR = 'Date cannot be set after {DATE}';
export const MIN_DATE_ERROR = 'Date cannot be set before {DATE}';
export const INVALID_DATE_ERROR = 'Date is invalid';
