import React from 'react';
import palette from 'components/theme/palette';

type Props = {
  taskType:
    | 'charge'
    | 'deploy'
    | 'diagnose'
    | 'hotspot'
    | 'move'
    | 'retrieve'
    | 'service'
    | 'swap'
    | 'multitask';
};

export const Pin = ({ taskType }: Props) => (
  <>
    <circle cx={6} cy={6} r={6} fill={palette.mapPin[taskType]} />
  </>
);
