import React, { CSSProperties } from 'react';
import * as CommonIcons from 'components/svg/CommonIcons';
import * as DensityPin from 'components/svg/Map/DensityPin';
import * as EarningsIcons from 'components/svg/EarningsIcons';
import * as FormIcons from 'components/svg/FormIcons';
import * as NavbarIcons from 'components/svg/NavbarIcons';
import * as TaskBadgeIcons from 'components/svg/Map/TaskBadgeIcons';
import * as TaskPinIcons from 'components/svg/Map/TaskPinIcons';
import * as TasksIcons from 'components/svg/TasksIcons';
import * as TaskVehicleIcons from 'components/svg/TaskVehicleIcons';
import * as VehicleIcons from 'components/svg/VehicleIcons';
import { VariantTypes, getViewBox } from 'constants/svg';

type SvgIconProps = {
  variant: VariantTypes;
  color?: string;
  size?: number;
  width?: number;
  height?: number;
  style?: CSSProperties;
  className?: string;
};

const SvgIcon: React.FC<SvgIconProps> = (props) => {
  const { variant, color, width, height, size, style, className } = props;
  const iconWidth = width || size;
  const iconHeight = height || size;
  const viewBox = getViewBox(variant);
  const iconParam = color ? { color } : undefined;

  const renderIcon = (iconProps) => ({
    analytics: <NavbarIcons.Analytics {...iconProps} />,
    assign: <FormIcons.Assign {...iconProps} />,
    assigned: <FormIcons.Assigned {...iconProps} />,
    authenticator: <NavbarIcons.Authenticator {...iconProps} />,
    batteryHalf: <CommonIcons.BatteryHalf {...iconProps} />,
    batteryFull: <CommonIcons.BatteryFull {...iconProps} />,
    booking: <NavbarIcons.Booking {...iconProps} />,
    bus: <VehicleIcons.Bus {...iconProps} />,
    calendar: <NavbarIcons.Calendar {...iconProps} />,
    call: <FormIcons.Call {...iconProps} />,
    car: <VehicleIcons.Car {...iconProps} />,
    charge: <TasksIcons.Charge {...iconProps} />,
    chargeWithColor: <TasksIcons.ChargeWithColor />,
    charts: <NavbarIcons.Charts {...iconProps} />,
    copy: <NavbarIcons.Copy {...iconProps} />,
    dashboard: <NavbarIcons.Dashboard {...iconProps} />,
    deploy: <TasksIcons.Deploy {...iconProps} />,
    deployWithColor: <TasksIcons.DeployWithColor />,
    diagnose: <TasksIcons.Diagnose {...iconProps} />,
    diagnoseWithColor: <TasksIcons.DiagnoseWithColor />,
    download: <CommonIcons.Download {...iconProps} />,
    earnings: <NavbarIcons.Earnings {...iconProps} />,
    ebike: <VehicleIcons.Ebike {...iconProps} />,
    email: <FormIcons.Email {...iconProps} />,
    employeeManagement: <NavbarIcons.EmployeeManagement />,
    error: <NavbarIcons.Error {...iconProps} />,
    eye: <CommonIcons.Eye {...iconProps} />,
    eyeOff: <CommonIcons.EyeOff {...iconProps} />,
    filter: <CommonIcons.Filter {...iconProps} />,
    history: <EarningsIcons.History {...iconProps} />,
    income: <EarningsIcons.Income {...iconProps} />,
    kanban: <NavbarIcons.Kanban {...iconProps} />,
    language: <NavbarIcons.Language {...iconProps} />,
    lime: <CommonIcons.Lime {...iconProps} />,
    loading: <EarningsIcons.Loading {...iconProps} />,
    location: <EarningsIcons.Location {...iconProps} />,
    map: <NavbarIcons.Map {...iconProps} />,
    moped: <VehicleIcons.Moped {...iconProps} />,
    move: <TasksIcons.Move {...iconProps} />,
    moveWithColor: <TasksIcons.MoveWithColor />,
    password: <FormIcons.Password {...iconProps} />,
    percentage: <FormIcons.Percentage {...iconProps} />,
    pinCharge: <DensityPin.Pin taskType="charge" />,
    pinDeploy: <DensityPin.Pin taskType="deploy" />,
    pinDiagnose: <DensityPin.Pin taskType="diagnose" />,
    pinHotspot: <DensityPin.Pin taskType="hotspot" />,
    pinMove: <DensityPin.Pin taskType="move" />,
    pinRetrieve: <DensityPin.Pin taskType="retrieve" />,
    pinService: <DensityPin.Pin taskType="service" />,
    pinSwap: <DensityPin.Pin taskType="swap" />,
    pinMultitask: <DensityPin.Pin taskType="multitask" />,
    refresh: <CommonIcons.Refresh {...iconProps} />,
    retrieve: <TasksIcons.Retrieve {...iconProps} />,
    retrieveWithColor: <TasksIcons.RetrieveWithColor />,
    service: <TasksIcons.Service {...iconProps} />,
    serviceWithColor: <TasksIcons.ServiceWithColor />,
    scooter: <VehicleIcons.Scooter {...iconProps} />,
    scooterSeated: <VehicleIcons.ScooterSeated {...iconProps} />,
    settings: <NavbarIcons.Settings {...iconProps} />,
    swap: <TasksIcons.Swap {...iconProps} />,
    swapWithColor: <TasksIcons.SwapWithColor />,
    taskBadgeChargeEBike: <TaskBadgeIcons.Ebike taskType="charge" />,
    taskBadgeChargeMoped: <TaskBadgeIcons.Moped taskType="charge" />,
    taskBadgeChargeScooter: <TaskBadgeIcons.Scooter taskType="charge" />,
    taskBadgeChargeScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="charge" />
    ),
    taskBadgeDeployEBike: <TaskBadgeIcons.Ebike taskType="deploy" />,
    taskBadgeDeployMoped: <TaskBadgeIcons.Moped taskType="deploy" />,
    taskBadgeDeployScooter: <TaskBadgeIcons.Scooter taskType="deploy" />,
    taskBadgeDeployScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="deploy" />
    ),
    taskBadgeDiagnoseEBike: <TaskBadgeIcons.Ebike taskType="diagnose" />,
    taskBadgeDiagnoseMoped: <TaskBadgeIcons.Moped taskType="diagnose" />,
    taskBadgeDiagnoseScooter: <TaskBadgeIcons.Scooter taskType="diagnose" />,
    taskBadgeDiagnoseScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="diagnose" />
    ),
    taskBadgeMoveEBike: <TaskBadgeIcons.Ebike taskType="move" />,
    taskBadgeMoveMoped: <TaskBadgeIcons.Moped taskType="move" />,
    taskBadgeMoveScooter: <TaskBadgeIcons.Scooter taskType="move" />,
    taskBadgeMoveScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="move" />
    ),
    taskBadgeRetrieveEBike: <TaskBadgeIcons.Ebike taskType="retrieve" />,
    taskBadgeRetrieveMoped: <TaskBadgeIcons.Moped taskType="retrieve" />,
    taskBadgeRetrieveScooter: <TaskBadgeIcons.Scooter taskType="retrieve" />,
    taskBadgeRetrieveScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="retrieve" />
    ),
    taskBadgeServiceEBike: <TaskBadgeIcons.Ebike taskType="service" />,
    taskBadgeServiceMoped: <TaskBadgeIcons.Moped taskType="service" />,
    taskBadgeServiceScooter: <TaskBadgeIcons.Scooter taskType="service" />,
    taskBadgeServiceScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="service" />
    ),
    taskBadgeSwapEBike: <TaskBadgeIcons.Ebike taskType="swap" />,
    taskBadgeSwapMoped: <TaskBadgeIcons.Moped taskType="swap" />,
    taskBadgeSwapScooter: <TaskBadgeIcons.Scooter taskType="swap" />,
    taskBadgeSwapScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="swap" />
    ),
    taskBadgeMultitaskEBike: <TaskBadgeIcons.Ebike taskType="multitask" />,
    taskBadgeMultitaskMoped: <TaskBadgeIcons.Moped taskType="multitask" />,
    taskBadgeMultitaskScooter: <TaskBadgeIcons.Scooter taskType="multitask" />,
    taskBadgeMultitaskScooterSeated: (
      <TaskBadgeIcons.ScooterSeated taskType="multitask" />
    ),
    taskPinChargeEbike: <TaskPinIcons.Ebike taskType="charge" />,
    taskPinChargeEbikeHighest: <TaskPinIcons.EbikeHighest taskType="charge" />,
    taskPinChargeMoped: <TaskPinIcons.Moped taskType="charge" />,
    taskPinChargeMopedHighest: <TaskPinIcons.MopedHighest taskType="charge" />,
    taskPinChargeScooter: <TaskPinIcons.Scooter taskType="charge" />,
    taskPinChargeScooterHighest: (
      <TaskPinIcons.ScooterHighest taskType="charge" />
    ),
    taskPinChargeScooterSeated: (
      <TaskPinIcons.ScooterSeated taskType="charge" />
    ),
    taskPinChargeScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="charge" />
    ),
    taskPinDiagnoseEbike: <TaskPinIcons.Ebike taskType="diagnose" />,
    taskPinDiagnoseEbikeHighest: (
      <TaskPinIcons.EbikeHighest taskType="diagnose" />
    ),
    taskPinDiagnoseMoped: <TaskPinIcons.Moped taskType="diagnose" />,
    taskPinDiagnoseMopedHighest: (
      <TaskPinIcons.MopedHighest taskType="diagnose" />
    ),
    taskPinDiagnoseScooter: <TaskPinIcons.Scooter taskType="diagnose" />,
    taskPinDiagnoseScooterHighest: (
      <TaskPinIcons.ScooterHighest taskType="diagnose" />
    ),
    taskPinDiagnoseScooterSeated: (
      <TaskPinIcons.ScooterSeated taskType="diagnose" />
    ),
    taskPinDiagnoseScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="diagnose" />
    ),
    taskPinMoveEbike: <TaskPinIcons.Ebike taskType="move" />,
    taskPinMoveEbikeHighest: <TaskPinIcons.EbikeHighest taskType="move" />,
    taskPinMoveMoped: <TaskPinIcons.Moped taskType="move" />,
    taskPinMoveMopedHighest: <TaskPinIcons.MopedHighest taskType="move" />,
    taskPinMoveScooter: <TaskPinIcons.Scooter taskType="move" />,
    taskPinMoveScooterHighest: <TaskPinIcons.ScooterHighest taskType="move" />,
    taskPinMoveScooterSeated: <TaskPinIcons.ScooterSeated taskType="move" />,
    taskPinMoveScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="move" />
    ),
    taskPinRetrieveEbike: <TaskPinIcons.Ebike taskType="retrieve" />,
    taskPinRetrieveEbikeHighest: (
      <TaskPinIcons.EbikeHighest taskType="retrieve" />
    ),
    taskPinRetrieveMoped: <TaskPinIcons.Moped taskType="retrieve" />,
    taskPinRetrieveMopedHighest: (
      <TaskPinIcons.MopedHighest taskType="retrieve" />
    ),
    taskPinRetrieveScooter: <TaskPinIcons.Scooter taskType="retrieve" />,
    taskPinRetrieveScooterHighest: (
      <TaskPinIcons.ScooterHighest taskType="retrieve" />
    ),
    taskPinRetrieveScooterSeated: (
      <TaskPinIcons.ScooterSeated taskType="retrieve" />
    ),
    taskPinRetrieveScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="retrieve" />
    ),
    taskPinServiceEbike: <TaskPinIcons.Ebike taskType="service" />,
    taskPinServiceEbikeHighest: (
      <TaskPinIcons.EbikeHighest taskType="service" />
    ),
    taskPinServiceMoped: <TaskPinIcons.Moped taskType="service" />,
    taskPinServiceMopedHighest: (
      <TaskPinIcons.MopedHighest taskType="service" />
    ),
    taskPinServiceScooter: <TaskPinIcons.Scooter taskType="service" />,
    taskPinServiceScooterHighest: (
      <TaskPinIcons.ScooterHighest taskType="service" />
    ),
    taskPinServiceScooterSeated: (
      <TaskPinIcons.ScooterSeated taskType="service" />
    ),
    taskPinServiceScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="service" />
    ),
    taskPinSwapEbike: <TaskPinIcons.Ebike taskType="swap" />,
    taskPinSwapEbikeHighest: <TaskPinIcons.EbikeHighest taskType="swap" />,
    taskPinSwapMoped: <TaskPinIcons.Moped taskType="swap" />,
    taskPinSwapMopedHighest: <TaskPinIcons.MopedHighest taskType="swap" />,
    taskPinSwapScooter: <TaskPinIcons.Scooter taskType="swap" />,
    taskPinSwapScooterHighest: <TaskPinIcons.ScooterHighest taskType="swap" />,
    taskPinSwapScooterSeated: <TaskPinIcons.ScooterSeated taskType="swap" />,
    taskPinSwapScooterSeatedHighest: (
      <TaskPinIcons.ScooterSeatedHighest taskType="swap" />
    ),
    taskVehicleChargeEbike: <TaskVehicleIcons.Ebike taskType="charge" />,
    taskVehicleChargeMoped: <TaskVehicleIcons.Moped taskType="charge" />,
    taskVehicleChargeScooter: <TaskVehicleIcons.Scooter taskType="charge" />,
    taskVehicleDiagnoseEbike: <TaskVehicleIcons.Ebike taskType="diagnose" />,
    taskVehicleDiagnoseMoped: <TaskVehicleIcons.Moped taskType="diagnose" />,
    taskVehicleDiagnoseScooter: (
      <TaskVehicleIcons.Scooter taskType="diagnose" />
    ),
    taskVehicleMoveEbike: <TaskVehicleIcons.Ebike taskType="move" />,
    taskVehicleMoveMoped: <TaskVehicleIcons.Moped taskType="move" />,
    taskVehicleMoveScooter: <TaskVehicleIcons.Scooter taskType="move" />,
    taskVehicleRetrieveEbike: <TaskVehicleIcons.Ebike taskType="retrieve" />,
    taskVehicleRetrieveMoped: <TaskVehicleIcons.Moped taskType="retrieve" />,
    taskVehicleRetrieveScooter: (
      <TaskVehicleIcons.Scooter taskType="retrieve" />
    ),
    taskVehicleServiceEbike: <TaskVehicleIcons.Ebike taskType="service" />,
    taskVehicleServiceMoped: <TaskVehicleIcons.Moped taskType="service" />,
    taskVehicleServiceScooter: <TaskVehicleIcons.Scooter taskType="service" />,
    taskVehicleSwapEbike: <TaskVehicleIcons.Ebike taskType="swap" />,
    taskVehicleSwapMoped: <TaskVehicleIcons.Moped taskType="swap" />,
    taskVehicleSwapScooter: <TaskVehicleIcons.Scooter taskType="swap" />,
    team: <NavbarIcons.Team {...iconProps} />,
    train: <VehicleIcons.Train {...iconProps} />,
    trophy: <NavbarIcons.Trophy {...iconProps} />,
    truck: <VehicleIcons.Truck {...iconProps} />,
    upload: <NavbarIcons.Upload {...iconProps} />,
    user: <NavbarIcons.User {...iconProps} />,
    walk: <VehicleIcons.Walk {...iconProps} />,
  });

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      viewBox={viewBox}
      style={style}
      className={className}
    >
      {renderIcon(iconParam)[variant]}
    </svg>
  );
};

SvgIcon.defaultProps = {
  size: 24,
};

export default SvgIcon;
