// import moment from 'moment-timezone';
import { PerformanceFilters } from 'types/performance.d';
import { Column, ORDER_VALUES } from 'types/table.d';
import { ALL_REGIONS } from './common';
import {
  CHARGE_TASK,
  DEPLOY_TASK,
  MOVE_TASK,
  REBALANCE_TASK,
  RETRIEVAL_TASK,
  SWAP_TASK,
  SERVICE_TASK,
} from 'constants/tasks';

export const PERFORMANCE_DEFAULT_TAB = 'completion';
export const PERFORMANCE_DEFAULT_VEHICLE_TYPE = 'scooter';
export const PERFORMANCE_TASK_TYPES = [
  SWAP_TASK,
  CHARGE_TASK,
  RETRIEVAL_TASK,
  REBALANCE_TASK,
  DEPLOY_TASK,
  SERVICE_TASK,
];

export const INITIAL_FILTERS_FORM: PerformanceFilters = {
  startDate: new Date(),
  endDate: new Date(),
  regionName: ALL_REGIONS.name,
  regionToken: ALL_REGIONS.token,
  status: 'completed',
};

export const PERFORMANCE_TABS_OPTIONS = {
  completion: 'Completion',
};

export const PERFORMANCE_GRID_COLUMNS: Column[] = [
  {
    id: 'employee',
    initialOrder: ORDER_VALUES.Asc,
    label: 'Employee Name',
    sortable: true,
  },
  {
    id: 'totalTasks',
    initialOrder: ORDER_VALUES.Desc,
    label: 'Total Tasks',
    content: ({ totalTasks }: { totalTasks: number }): string =>
      `${totalTasks.toLocaleString()} tasks`,
    width: 160,
    sortable: true,
  },
  {
    id: 'swap',
    label: 'Swap',
    width: 100,
  },
  {
    id: 'charge',
    label: 'Charge',
    width: 100,
  },
  {
    id: 'retrieve',
    label: 'Retrieve',
    width: 100,
  },
  {
    id: 'move',
    label: 'Move',
    width: 100,
  },
  {
    id: 'deploy',
    label: 'Deploy',
    width: 100,
  },
  {
    id: 'service',
    label: 'Service',
    width: 100,
  },
];
