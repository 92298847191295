import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MENU_SECTIONS, MENU_TO_ALLOWED_REGIONS_MAP } from 'constants/menu';
import { closeAlertMessage } from 'reduxStore/actions/messages';

export const getOptionIdFromPath = (path: string): string => {
  let selectedOption = '';

  MENU_SECTIONS.forEach((section) => {
    const option = section.items.find((item) => path.startsWith(item.link));

    if (option) {
      selectedOption = option.id;
    }
  });

  return selectedOption;
};

export const checkIsMenuItemAllowedForRegion = (
  menuId: string,
  regionToken: string,
): boolean => {
  const allowedRegionTokens = new Set(MENU_TO_ALLOWED_REGIONS_MAP[menuId]);
  return allowedRegionTokens.has(regionToken) || allowedRegionTokens.size === 0;
};

export const useSelectedMenuItem = (): [
  string | undefined,
  (optionId: string, link: string, onOptionSelect?: () => void) => void,
] => {
  const [selectedItem, setSelectedItem] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onRedirect = (
    optionId: string,
    link: string,
    onOptionSelect?: () => void,
  ) => {
    setSelectedItem(optionId);
    dispatch(closeAlertMessage());
    navigate(link);
    onOptionSelect && onOptionSelect();
  };

  useEffect(() => {
    const selectedOption = getOptionIdFromPath(pathname);
    setSelectedItem(selectedOption);
  }, [pathname]);

  return [selectedItem, onRedirect];
};
