import { alpha } from '@mui/material/styles';
import {
  CustomColor,
  PaletteColorScales,
  PaletteInputColors,
  PaletteGradients,
} from 'types/theme.d';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY: CustomColor = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};
const ORANGE: PaletteColorScales = {
  main: '#fb8800',
  dark: '#c96f00',
};
const BLUE: PaletteColorScales = {
  lighter: '#F2F8FE',
  main: '#0077F7',
};

const PRIMARY: PaletteColorScales = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#00AB55',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};
const SECONDARY: PaletteColorScales = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00C700',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
};
const INFO: PaletteColorScales = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
const SUCCESS: PaletteColorScales = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};
const WARNING: PaletteColorScales = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};
const ERROR: PaletteColorScales = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};
const SIDE_MENU = {
  text: {
    main: '#637381',
    selected: PRIMARY.main,
  },
  option: {
    selected: 'rgba(0, 171, 85, .08)',
  },
};
const INPUT: PaletteInputColors = {
  base: alpha('#919eab', 0.32),
  disabled: '#919eab',
  error: ERROR.main,
  focus: PRIMARY.main,
  hover: '#000',
};

const GRADIENTS: PaletteGradients = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

// color scheme defined in figma:
// https://www.figma.com/file/P3DuAYjkvNDwZI6jx9iaaK/LP-Dashboard-Design-system?type=design&node-id=230-1&mode=design&t=Y01ixdsU67MEHXxN-0
const TASKS_COLORS = {
  charge: '#FFF2D4',
  chargeDarker: '#7A4F01',
  diagnose: '#9E86FF',
  move: '#00AB551F',
  moveDarker: '#00AB55',
  retrieve: '#FF48421F',
  retrieveDarker: '#FF4842',
  service: '#9E86FF1F',
  serviceDarker: '#826AF9',
  swap: '#1890FF1F',
  swapDarker: '#1890FF',
  deploy: '#FFF3D6',
  deployDarker: '#FFC107',
};

// color scheme defined in figma:
// https://www.figma.com/file/P3DuAYjkvNDwZI6jx9iaaK/LP-Dashboard-Design-system?type=design&node-id=857-77798&mode=design&t=ubGyvxxEfO2Vrhvs-11
const MAP_PINS_COLORS = {
  charge: '#7A4F01',
  deploy: '#FFC107',
  diagnose: '#826AF9',
  move: '#00AB55',
  retrieve: '#FF4842',
  service: '#826AF9',
  swap: '#1890FF',
  hotspot: '#000000',
  multitask: '#EC8103',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  input: { ...INPUT },
  grey: GREY,
  orange: { ...ORANGE },
  blue: { ...BLUE },
  yellow: '#FCEB2A',
  purple: 'rgb(153, 141, 205)',
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: GREY[200],
  },
  task: { ...TASKS_COLORS },
  mapPin: { ...MAP_PINS_COLORS },
  sideMenu: { ...SIDE_MENU },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  border: {
    color: '#c6cbd4',
  },
  logout: {
    button: {
      background: ERROR.main,
    },
  },
  user: {
    background: GREY[200],
  },
};

export default palette;
