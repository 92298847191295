import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reduxStore/types';
import { useLocation, Navigate } from 'react-router-dom';
import { BASE_PATH } from 'constants/app';
import { LOGGED_IN } from 'constants/loginPhases';
import { IS_IMPERSONATING, LOGIN_PHASE } from 'constants/localStorage';

const withAccessControl = <P, >(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> => {
  const SecuredControl = (props: P) => {
    const location = useLocation();
    const isSignedIn = useSelector(
      (state: AppState) => state.session.isSignedIn,
    );
    const isLoginPage = location.pathname.includes('/login');
    const loginPhase = Number(localStorage.getItem(LOGIN_PHASE));
    const isImpersonating = !!localStorage.getItem(IS_IMPERSONATING);
    const loginPage = isImpersonating ? 'login/impersonate' : 'login';

    if (!isLoginPage && !isSignedIn && loginPhase !== LOGGED_IN) {
      // Redirect to the login page if the user is not logged in and tries to access a valid section.
      return <Navigate to={`${BASE_PATH}/${loginPage}`} />;
    }

    if (isLoginPage && isSignedIn && loginPhase === LOGGED_IN) {
      // Redirect to the Dashboard page is the user is already logged in and tries to access the login Page.
      return <Navigate to={`${BASE_PATH}/dashboard`} />;
    }

    // Render the Wrapped Component.
    return <WrappedComponent {...props} />;
  };

  return SecuredControl;
};

export default withAccessControl;
