import React from 'react';
import palette from 'components/theme/palette';

type Props = {
  taskType:
    | 'charge'
    | 'deploy'
    | 'diagnose'
    | 'move'
    | 'move'
    | 'retrieve'
    | 'service'
    | 'swap'
    | 'multitask';
};

export const Ebike = ({ taskType }: Props) => (
  <>
    <circle cx={14} cy={14} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M15.043 8h-2.45l-3.81 9.044M20.608 12.174h-1.46l-.627 1.391M12.4 12.638l3.328 4.266H18.8"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={9.2}
      cy={16.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
    <circle
      cx={18.8}
      cy={16.904}
      r={3.2}
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);

export const Moped = ({ taskType }: Props) => (
  <>
    <circle cx={14} cy={14} r={14} fill={palette.mapPin[taskType]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.318 7.598a.478.478 0 0 1 .429-.268h2.168a.478.478 0 1 1 0 .955h-1.87l-1.178 2.485a.478.478 0 1 1-.859-.419l1.31-2.753ZM9.506 14.665c.217.112.303.38.19.597l-1.552 3.003a.443.443 0 1 1-.788-.407l1.552-3.003a.443.443 0 0 1 .598-.19Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 16.336a1.795 1.795 0 1 0 0 3.59 1.795 1.795 0 0 0 0-3.59ZM5 18.131a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM17.54 18.13a1.795 1.795 0 1 0 3.59 0h.956a2.75 2.75 0 0 1-5.501 0h.956Z"
      fill={palette.common.white}
      stroke={palette.common.white}
      strokeWidth={0.6}
    />
    <path
      d="M12.434 9.82s-2.46 6.763 1.24 6.763c3.5 0 3.559-1.908 2.917-2.55H20.5c.776.592 3.637 4.03 1.645 4.03-3.39 0-6.7-.004-9.916-.004-.822.036-.919-.633-.919-.633-.25-.918-1.203-2.561-2.45-2.561-1.156 0 1.441-4.083 2.328-5.044h1.247Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.067 9.71a.163.163 0 0 1 .12-.053h1.247a.163.163 0 0 1 .154.22l-.154-.056.154.056-.002.004-.005.014a6.892 6.892 0 0 0-.09.267c-.06.183-.14.444-.226.758a13.72 13.72 0 0 0-.436 2.297c-.075.836-.032 1.647.24 2.242.134.294.323.531.579.696.255.165.59.265 1.027.265 1.728 0 2.548-.472 2.875-.962.327-.49.194-1.041-.074-1.31a.163.163 0 0 1 .116-.278h3.907c.036 0 .07.012.1.034.208.158.541.495.89.902.35.41.723.903 1.01 1.379.144.238.268.475.357.698.087.221.144.438.144.633 0 .198-.06.386-.216.521-.153.13-.37.188-.64.188l-5.023-.001h-.07l-4.82-.002c-.461.02-.74-.162-.9-.366a.967.967 0 0 1-.181-.393v-.002a4.625 4.625 0 0 0-.819-1.557c-.414-.511-.922-.876-1.472-.876a.465.465 0 0 1-.266-.074.41.41 0 0 1-.157-.215c-.053-.159-.034-.36.015-.565.1-.42.353-.976.662-1.548.62-1.148 1.498-2.422 1.954-2.916Zm.383 7.615.829.57 4.777.002h.065l5.024.002c.229 0 .357-.05.426-.11.065-.055.103-.14.102-.273 0-.137-.04-.31-.121-.513-.08-.2-.194-.42-.332-.65a10.552 10.552 0 0 0-.98-1.335 7.28 7.28 0 0 0-.797-.821H16.91c.21.395.24.95-.09 1.443-.417.624-1.376 1.107-3.146 1.107-.488 0-.887-.112-1.204-.317a1.912 1.912 0 0 1-.7-.836c-.307-.673-.344-1.553-.267-2.407.078-.858.272-1.714.446-2.352.087-.32.17-.587.23-.774.01-.027.018-.053.025-.077h-.945c-.444.502-1.269 1.703-1.86 2.797-.306.568-.541 1.093-.63 1.469-.047.192-.047.318-.024.386.01.03.021.041.03.048.011.007.035.018.083.018.697 0 1.289.457 1.726.996.422.522.726 1.152.865 1.627Z"
      fill={palette.common.white}
    />
    <path
      d="M14.914 13.618c0-.208.168-.376.376-.376h5.678a.376.376 0 0 1 0 .752H15.29a.376.376 0 0 1-.376-.376Z"
      fill={palette.common.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.47 13.618a.82.82 0 0 1 .82-.82h5.678a.82.82 0 0 1 0 1.64H15.29a.82.82 0 0 1-.82-.82Z"
      fill={palette.common.white}
    />
  </>
);

export const Scooter = ({ taskType }: Props) => (
  <>
    <circle cx={14} cy={14} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M18.371 19.696H11.75l-2.81-3.763 3.39-9.183h3.092"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.125 19.468a1.31 1.31 0 0 1-1.313 1.307A1.31 1.31 0 0 1 6.5 19.468c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308ZM21.5 19.468a1.31 1.31 0 0 1-1.313 1.307 1.31 1.31 0 0 1-1.312-1.307c0-.72.586-1.308 1.313-1.308a1.31 1.31 0 0 1 1.312 1.308Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);

export const ScooterSeated = ({ taskType }: Props) => (
  <>
    <circle cx={14} cy={14} r={14} fill={palette.mapPin[taskType]} />
    <path
      d="M14.84 6.75h-3.498l-2.405 8.668 2.813 3.933h6.443"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.404 13.156h-3.656l-1.423 5.71"
      stroke={palette.common.white}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.125 19.696c0 .717-.585 1.304-1.313 1.304A1.308 1.308 0 0 1 6.5 19.696c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304ZM21.5 19.696c0 .717-.585 1.304-1.313 1.304a1.308 1.308 0 0 1-1.312-1.304c0-.717.585-1.304 1.313-1.304.727 0 1.312.587 1.312 1.304Z"
      stroke={palette.common.white}
      strokeWidth={1.5}
    />
  </>
);
