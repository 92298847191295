import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import Header from 'components/Layout/Header';
import SideMenu from 'components/Layout/Menu/SideMenu';
import ThemeConfig from 'components/theme';
import GlobalStyles from 'components/theme/globalStyles';
import { pxToRem } from 'components/theme/typography';
import { SIDE_MENU_WIDTH } from 'constants/app';
import AlertMessage from 'core/AlertMessage';
import RouteContent from 'core/RouteContent';
import { AppState } from 'reduxStore/types';
import { initAmplitude } from 'lib/amplitude';
import { initDatadog } from 'lib/datadog';
import { getLastRequestId } from 'reduxStore/actions/messages';
import { validateSession } from 'reduxStore/actions/session';
import MobileMenu from 'components/Layout/Menu/MobileMenu';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

const RootStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    mx: 'auto',
    my: 0,
    display: 'flex',
    height: '100%',
    maxWidth: 1680,
    overflow: 'hidden',
    position: 'relative',
    boxShadow: {
      xs: 'none',
      xl: `${pxToRem(-2)} 0 ${pxToRem(5)} 0 ${alpha(theme.palette.common.black, 0.75)},
        ${pxToRem(2)} 0 ${pxToRem(5)} 0 ${alpha(theme.palette.common.black, 0.75)}`,
    },
  }),
);

const MainContainerStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
  }),
);

initDatadog();
initAmplitude();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(
    (state: AppState) => state?.session?.isSignedIn,
  );

  const loggedUsersWidth = {
    xs: '100%',
    lg: `calc(100% - ${SIDE_MENU_WIDTH})`,
  };

  useEffect(() => {
    // Validate the user's session.
    dispatch(validateSession());

    // Get the Last Request ID (if any).
    dispatch(getLastRequestId());
  }, [validateSession, getLastRequestId]);

  return (
    <>
      <Helmet defaultTitle="Lime LP Dashboard">
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="robots" content="noindex" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css?family=Public+Sans|Be+Vietnam+Pro"
          rel="stylesheet"
        />
      </Helmet>
      <AlertMessage />
      <ThemeConfig>
        <GlobalStyles />
        <BrowserRouter>
          <RootStyle>
            <SideMenu data-e2e="side-menu" />
            <MainContainerStyle
              sx={{
                width: !isSignedIn ? '100%' : loggedUsersWidth,
              }}
            >
              <MobileMenu data-e2e="mobile-menu" />
              <Header data-e2e="header" />
              <Suspense fallback={<CircularProgress />}>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <RouteContent />
                </ErrorBoundary>
              </Suspense>
            </MainContainerStyle>
          </RootStyle>
        </BrowserRouter>
      </ThemeConfig>
    </>
  );
};

export default App;
