import { omit } from 'lodash';
import {
  LIVE_MAP_BOUNDS,
  LIVE_MAP_CENTER,
  LIVE_MAP_FILTERS,
  LIVE_MAP_ZOOM,
} from 'constants/localStorage';
import {
  CSV_DOWNLOAD_FILE_EVENT,
  LIVE_MAP_PAGE,
  LIVE_MAP_FETCH_DATA_EVENT,
  MY_TASKS_PAGE,
  MY_TASKS_FETCH_DATA_EVENT,
  MY_TASKS_FETCH_FILTER_OPTIONS_EVENT,
} from 'constants/amplitude';
import { DEFAULT_MAP_ZOOM, LIVE_MAP_INITIAL_FILTERS } from 'constants/maps';
import { REBALANCE_TASK, MOVE_TASK } from 'constants/tasks';
import { logAction } from 'lib/amplitude';
import api from 'lib/api';
import { downloadCsvFile } from 'lib/csv';
import { formatParamsForAPI, isBetween } from 'lib/generalUtils';
import { getMapCenter } from 'lib/maps';
import { formatMyTaskFilters, formatLiveMapFilters } from 'lib/tasks';
import { ApiError } from 'types/global.d';
import { MapboxBounds, MapCoordinates } from 'types/maps.d';
import { CsvParams, MyTasksFiltersType, TasksDataType } from 'types/tasks.d';
import { handleError, closeAlertMessage } from './messages';
import actionTypes from './actionTypes';
import '../reducers/tasks';

/**
 * Triggers the reducer for updating the User's Tasks Filters with the fetched data.
 * @param filters
 */
const updateTasksFiltersOptions = (filters) => ({
  type: actionTypes.TASKS_UPDATE_FILTER_OPTIONS,
  payload: filters,
});

/**
 * Gets the user's tasks filters.
 */
export const fetchTasksFiltersOptions = () => async (dispatch) => {
  // Close any existing alert message.
  dispatch(closeAlertMessage());

  try {
    // Fetch the User's Tasks filters.
    const filters = (await api.get('logistics_partner/v1/tasks/filter', {}))
      .data;

    // Update the user's Tasks Filters into the Store.
    dispatch(updateTasksFiltersOptions(filters));

    // Log the action into Amplitude.
    logAction(MY_TASKS_PAGE, MY_TASKS_FETCH_FILTER_OPTIONS_EVENT, {
      filters,
      count: filters.length,
    });
  } catch (error) {
    const errorData: ApiError = {
      error,
      consoleMessage: 'Error fetching filter options for My Tasks',
      amplitudePage: MY_TASKS_PAGE,
      amplitudeEvent: MY_TASKS_FETCH_FILTER_OPTIONS_EVENT,
      alertMessage: 'Cannot get filter options for My Tasks',
    };
    dispatch(handleError(errorData));
  }
};

/**
 * Updates the 'Loading Data' status. This is used when fetching the Tasks/Map data.
 * @param status
 */
const updateLoadingStatus = (actionType: string, status: boolean) => ({
  type: actionType,
  payload: status,
});

/**
 * Updates the 'Page Number' for the pagination on My Tasks.
 * @param newPage
 */
export const updateTasksTablePageNumber = (newPage: number) => ({
  type: actionTypes.TASKS_UPDATE_PAGE_NUMBER,
  payload: newPage,
});

/**
 * Updates the 'Page Size' for the pagination on My Tasks.
 * @param newSize
 */
export const updateTasksTablePageSize = (newSize: number) => ({
  type: actionTypes.TASKS_UPDATE_PAGE_SIZE,
  payload: newSize,
});

/**
 * Updates the 'Filters' for My Tasks.
 * @param newFilters
 */
export const updateMyTasksFilters = (newFilters: MyTasksFiltersType) => ({
  type: actionTypes.TASKS_UPDATE_FILTERS,
  payload: newFilters,
});

/**
 * Cleans the existing value for the 'Total Tasks' which is displayed into the current Tab on My Tasks.
 */
export const unsetMyTasksTotalTasks = () => ({
  type: actionTypes.TASKS_UNSET_TOTAL_TASKS,
  payload: undefined,
});

/**
 * Updates the 'Status' for My Tasks.
 * @param taskStatus
 */
export const updateTaskStatus = (taskStatus: string) => ({
  type: actionTypes.TASKS_UPDATE_STATUS,
  payload: taskStatus,
});

/**
 * Updates the 'Data' fetched for My Tasks.
 * @param taskStatus
 * @param tasksData
 * @param totalTasks
 */
const updateTasksData = (tasksData: TasksDataType, totalTasks: number) => ({
  type: actionTypes.TASKS_UPDATE_DATA,
  payload: {
    tasksData,
    totalTasks,
  },
});

/**
 * Fetches the 'Data` for My Tasks based on the applied filters and the task status.
 */
export const fetchTasksData = () => async (dispatch, getState) => {
  // Close any existing alert message.
  dispatch(closeAlertMessage());

  // Fetch params from Redux Store.
  const {
    tasks: {
      myTasks: { filters, tablePageSize, tablePageNumber, status },
    },
    session: { timezone },
  } = getState();

  const datetime = new Date();
  const triggeredOn = datetime.getTime();

  let formattedFilters: Record<string, any> = formatParamsForAPI(
    formatMyTaskFilters(filters, timezone),
  );

  if (formattedFilters.task_type) {
    formattedFilters.task_type = formattedFilters.task_type.replace(
      REBALANCE_TASK,
      MOVE_TASK,
    );
  }

  // Remove the Date Range properties if the tasks to fetch are the `Collected` ones.
  if (status === 'active') {
    formattedFilters = omit(formattedFilters, [
      'ended_at_utc_geq',
      'ended_at_utc_leq',
    ]);
  }

  try {
    dispatch(
      updateLoadingStatus(actionTypes.TASKS_UPDATE_LOADING_STATUS, true),
    );
    const response = // Fetch the Tasks Data with the provided filters.
      (
        await api.get('logistics_partner/v1/tasks', {
          status,
          ...formattedFilters,
          page_limit: tablePageSize,
          page: tablePageNumber + 1,
          triggeredOn,
        })
      ).data;

    // Update the Tasks Data into the Store.
    dispatch(updateTasksData(response.tasks, response.numTotalTasks));

    // Log the action into Amplitude.
    logAction(MY_TASKS_PAGE, MY_TASKS_FETCH_DATA_EVENT, {
      status,
      filters: formattedFilters,
      pageNumber: tablePageNumber + 1,
      pageLimit: tablePageSize,
      totalTasks: response.numTotalTasks,
      count: response.tasks.length,
    });
  } catch (error) {
    const errorData: ApiError = {
      error,
      consoleMessage: 'Error fetching tasks data for My Tasks',
      amplitudePage: MY_TASKS_PAGE,
      amplitudeEvent: MY_TASKS_FETCH_DATA_EVENT,
      alertMessage: 'Cannot get data for My Tasks',
    };
    dispatch(handleError(errorData));
  } finally {
    dispatch(
      updateLoadingStatus(actionTypes.TASKS_UPDATE_LOADING_STATUS, false),
    );
  }
};

/**
 * Updates the 'Downloading Data' status. This is used when downloading a file.
 * @param status
 */
const updateDownloadingStatus = (status: boolean) => ({
  type: actionTypes.TASKS_UPDATE_DOWNLOADING_STATUS,
  payload: status,
});

/**
 * Downloads a CSV file based on the provided params.
 * @param params
 */
export const downloadCsv = (params: CsvParams) => async (dispatch) => {
  // Close any existing alert message.
  dispatch(closeAlertMessage());

  const { fromTs, toTs, columns, additionalParams, reportName, pageName } =
    params;
  try {
    dispatch(updateDownloadingStatus(true));

    // Fetch the data for exporting a CSV file with the provided params.
    const res = (
      await api.get('logistics_partner/v1/tasks/export_csv', {
        from_ts: fromTs,
        to_ts: toTs,
        cols_to_include: columns,
        ...additionalParams,
      })
    ).data;

    // Define the file name.
    const fileName = `${reportName}_${fromTs}_to_${toTs}`;

    // Download the CSV file.
    downloadCsvFile(res, fileName);

    // Log the action into Amplitude.
    logAction(pageName, CSV_DOWNLOAD_FILE_EVENT, {
      fileName,
      downloadedFromPage: pageName,
      fromTs,
      toTs,
      colsToInclude: columns,
      additionalParams,
    });
  } catch (error) {
    const errorData: ApiError = {
      error,
      consoleMessage: 'Error downloading csv file',
      amplitudePage: pageName,
      amplitudeEvent: CSV_DOWNLOAD_FILE_EVENT,
      alertMessage: 'Error downloading CSV',
    };
    dispatch(handleError(errorData));
  } finally {
    dispatch(updateDownloadingStatus(false));
  }
};

/**
 * Updates the Map Center.
 * @param newCenter { lat: number, lng: number }
 */
export const updateMapCenter = (newCenter: MapCoordinates) => {
  // Store the new center coordinates into the local storage.
  localStorage.setItem(LIVE_MAP_CENTER, JSON.stringify(newCenter));

  // Dispatch the update for the Map Center.
  return {
    type: actionTypes.TASKS_MAP_UPDATE_MAP_CENTER,
    payload: newCenter,
  };
};

/**
 * Updates the Map Bounds.
 * @param newBounds [[neLng: number, neLat: number], [swLng: number, swLat: number]]
 */
export const updateMapBounds = (newBounds: MapboxBounds) => {
  // Store the new center coordinates into the local storage.
  localStorage.setItem(LIVE_MAP_BOUNDS, JSON.stringify(newBounds));

  // Dispatch the update for the Map Center.
  return {
    type: actionTypes.TASKS_MAP_UPDATE_MAP_BOUNDS,
    payload: newBounds,
  };
};

/**
 * Updates the Map Zoom Level.
 * @param newZoomLevel
 */
export const updateMapZoom = (newZoomLevel: number) => {
  // Store the new zoom level into the local storage.
  localStorage.setItem(LIVE_MAP_ZOOM, newZoomLevel.toString());

  // Dispatch the update for the Map Zoom.
  return {
    type: actionTypes.TASKS_MAP_UPDATE_ZOOM_LEVEL,
    payload: newZoomLevel,
  };
};

/**
 * Helper for filtering the 'Map Data' based on the provided filters.
 * @param taskRecord
 * @param filters
 */
export const validateFilters = (taskRecord, filters) => {
  const { type, bike, multitaskTypes } = taskRecord?.attributes;
  const {
    batteryPercentage,
    typeName: vehicleType,
    plateNumber,
  } = bike?.attributes;

  const {
    plate_number: filterPlateNumber,
    vehicle_type: filterVehicleType,
    task_type: filterTaskType,
    battery_level_min: batteryLevelMin,
    battery_level_max: batteryLevelMax,
  } = filters;

  let pass =
    filterVehicleType.includes(vehicleType) && filterTaskType.includes(type);

  // we pass multitasks when the multitask filter is enabled and
  // at least one of the individual task type filters for the
  // multitaskTypes is enabled
  if (
    pass &&
    filterTaskType.includes('Multitask') &&
    type === 'Multitask' &&
    multitaskTypes
  ) {
    pass = multitaskTypes.some((multitaskType) => {
      const actualMultitaskType =
        multitaskType === MOVE_TASK ? REBALANCE_TASK : multitaskType;
      return filterTaskType.includes(actualMultitaskType);
    });
  }

  if (pass && filterPlateNumber) {
    pass = filterPlateNumber.includes(plateNumber);
  }

  if (pass && typeof batteryLevelMin === 'number') {
    pass = batteryLevelMin <= batteryPercentage;
  }

  if (pass && typeof batteryLevelMax === 'number') {
    pass = batteryLevelMax >= batteryPercentage;
  }

  return pass;
};

/**
 * Updates the 'Filters' for Live Map.
 * @param filters
 */
export const updateTasksMapFilters = (filters) => ({
  type: actionTypes.TASKS_MAP_UPDATE_FILTERS,
  payload: filters,
});

/**
 * Updates the 'Data' fetched for Live Map.
 * @param type
 * @param taskMapData
 */
export const updateTasksMapData = (type, taskMapData) => ({
  type,
  payload: taskMapData,
});

/**
 * Applies the filters to the 'fetched data' for Live Map.
 * @param filters
 */
export const updateMapFilteredData = (filters) => (dispatch, getState) => {
  const {
    tasks: {
      mapData: { data },
    },
  } = getState();

  // Format the filters to API params and transform them to plain strings.
  const plainFilters: Record<string, unknown> = formatParamsForAPI(
    formatLiveMapFilters(filters),
  );

  // Validate the filters to identify those that need to be sent to the API request.
  const filteredData =
    !!plainFilters.vehicle_type && !!plainFilters.task_type
      ? data.filter((task) => validateFilters(task, plainFilters))
      : [];

  // Update the filters into the Store.
  dispatch(updateTasksMapFilters(filters));

  // Filter the Map Data, into the Store, with the provided filters.
  dispatch(
    updateTasksMapData(
      actionTypes.TASKS_MAP_UPDATE_FILTERED_DATA,
      filteredData,
    ),
  );
};

/**
 * Fetches the 'Data` for Live Map.
 */
export const fetchTasksMapData = () => async (dispatch, getState) => {
  // Close any existing alert message.
  dispatch(closeAlertMessage());

  const {
    session: { lpRegion },
  } = getState();
  const {
    attributes: {
      neLongitude: neLng,
      neLatitude: neLat,
      swLongitude: swLng,
      swLatitude: swLat,
    },
  } = lpRegion;
  const regionBounds: MapboxBounds = [
    [swLng, swLat],
    [neLng, neLat],
  ];
  const storedCenter: MapCoordinates = JSON.parse(
    localStorage.getItem(LIVE_MAP_CENTER) as string,
  );
  const isCenterInBounds =
    isBetween(storedCenter.lat, swLat, neLat) &&
    isBetween(storedCenter.lng, swLng, neLng);
  const regionCenter: MapCoordinates = isCenterInBounds
    ? storedCenter
    : getMapCenter(swLat, neLat, swLng, neLng);
  const storedZoom = Number(localStorage.getItem(LIVE_MAP_ZOOM));
  const storedMapFilters = JSON.parse(
    localStorage.getItem(LIVE_MAP_FILTERS) as string,
  );
  const zoomLevel = storedZoom || DEFAULT_MAP_ZOOM;
  const datetime = new Date();
  const filters: any = LIVE_MAP_INITIAL_FILTERS;
  filters.taskType = filters.taskType.replace(REBALANCE_TASK, MOVE_TASK);
  filters.triggeredOn = datetime.getTime();
  const formattedFilters = formatParamsForAPI(filters);

  // Clear the existing Map Data.
  dispatch(updateTasksMapData(actionTypes.TASKS_MAP_UPDATE_DATA, []));

  try {
    dispatch(
      updateLoadingStatus(actionTypes.TASKS_MAP_UPDATE_LOADING_STATUS, true),
    );

    // Fetch the Map Data.
    const response = (
      await api.get('logistics_partner/v2/tasks/available_tasks', {
        region_token: lpRegion.regionToken,
        origin_latitude: regionCenter.lat,
        origin_longitude: regionCenter.lng,
        region_filter_method: 'geohash_match',
        ...formattedFilters,
      })
    ).data;

    // Update the Map Data, into the Store, with the fetched result.
    dispatch(
      updateTasksMapData(actionTypes.TASKS_MAP_UPDATE_DATA, response.tasks),
    );
    dispatch(updateMapFilteredData(storedMapFilters));

    // Update the Zoom level and the Map Center into the Store.
    dispatch(updateMapZoom(zoomLevel));
    dispatch(updateMapBounds(regionBounds));
    dispatch(updateMapCenter(regionCenter));

    // Log the action into Amplitude.
    logAction(LIVE_MAP_PAGE, LIVE_MAP_FETCH_DATA_EVENT, {
      regionToken: lpRegion.regionToken,
      originLatitude: regionCenter.lat,
      originLongitude: regionCenter.lng,
      filters: formattedFilters,
      count: response.tasks.length,
    });
  } catch (error) {
    const errorData: ApiError = {
      error,
      consoleMessage: 'Error fetching tasks data for Live Map',
      amplitudePage: LIVE_MAP_PAGE,
      amplitudeEvent: LIVE_MAP_FETCH_DATA_EVENT,
      alertMessage: 'Cannot get tasks data for Live Map',
    };
    dispatch(handleError(errorData));
  } finally {
    dispatch(
      updateLoadingStatus(actionTypes.TASKS_MAP_UPDATE_LOADING_STATUS, false),
    );
  }
};
