import { datadogRum } from '@datadog/browser-rum';
import {
  Alert,
  AlertTitle,
  Card,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  error: Error;
}

const ErrorBoundaryFallback: React.FC<Props> = ({ error }) => {
  const location = useLocation();
  const fullPath = `${location.pathname}${location.search}`;

  const rumContext = datadogRum.getInternalContext();
  const sessionId = rumContext?.session_id;

  const emitCrashEvent = () => {
    if (!datadogRum.getInitConfiguration()) {
      return;
    }

    // This is a hack to get the error to show up in Datadog until we figure
    // out how to attach the `is_crash` tag to the error.
    //
    // Issue: https://github.com/DataDog/browser-sdk/issues/2514
    datadogRum.addError(error, {
      is_crash: true,
    });
  };

  useEffect(() => {
    emitCrashEvent();
  }, []);

  return (
    <Card
      sx={{
        margin: 4,
        padding: 4,
        maxWidth: 800,
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h4">Something went wrong</Typography>
        <Divider />
        <Typography variant="body1">
          Please try refreshing the page. If the issue persists, reach out to
          your local Operations Manager to escalate this issue.
        </Typography>
        <Stack spacing={0}>
          <Typography variant="h6">Raw Error Message</Typography>
          <Alert severity="error" icon={false}>
            <AlertTitle>{error.name}</AlertTitle>
            {error.message}
          </Alert>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">Page:</Typography>
          <Chip label={fullPath} />
        </Stack>
        {sessionId && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6">Session ID:</Typography>
            <Chip label={sessionId} />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default ErrorBoundaryFallback;
