import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const AssetPage = lazy(() => import('pages/Assets/AssetsPage'));
const BatteryInventoryPage = lazy(
  () => import('pages/Assets/BatteryInventoryPage'),
);
const BatteryDetailPage = lazy(
  () =>
    import(
      'pages/Assets/components/BatteryDetail/components/BatteryDetailPage'
    ),
);
const SmartCartsDetailPage = lazy(
  () =>
    import(
      'pages/Assets/components/SmartCartsDetail/components/SmartCartsDetailPage'
    ),
);
const BatteryPossessionsTable = lazy(
  () => import('pages/Assets/components/BatteryPossessionsTable'),
);
const SmartCartsContent = lazy(
  () =>
    import('pages/Assets/components/SmartCarts/components/SmartCartsContent'),
);

export default (
  <>
    <Route path="assets" element={<AssetPage />}>
      <Route index element={<Navigate to="battery_possessions" />} />

      <Route path="battery_possessions">
        <Route index element={<BatteryPossessionsTable />} />
        <Route path="inventory" element={<BatteryInventoryPage />} />
      </Route>

      <Route path="smart_carts">
        <Route index element={<SmartCartsContent />} />
        <Route path=":id" element={<SmartCartsDetailPage />} />
      </Route>
    </Route>

    <Route path="battery/:id" element={<BatteryDetailPage />} />
  </>
);
