import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { BODY_VERTICAL_PADDING } from 'constants/common';
import { flattenedRoutesList } from 'routes/routesConfig';
import assetsRoutes from 'routes/assetsRoutes';
import { resizeWindow } from 'reduxStore/actions/session';
import { BreakpointsValues } from 'types/global.d';

const getWindowSize = () => {
  const { innerHeight, innerWidth } = window;
  return [innerWidth, innerHeight];
};

const ContentStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    px: { xs: pxToRem(16), lg: pxToRem(40) },
    py: pxToRem(BODY_VERTICAL_PADDING),
    flexGrow: 1,
    overflowY: 'auto',
    width: '100%',
    height: `calc(100% - ${pxToRem(24)})`,
  }),
);

const LoginStyle = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    pt: pxToRem(75),
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
  }),
);

const RouteMap = ({ routes }) => (
  <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} Component={route.component} />
    ))}

    {assetsRoutes}
  </Routes>
);

type RouteContentProps = {
  isSignedIn: boolean;
  dispatchResizeWindow: (
    newWindowWidth: number,
    newHeight: number,
    breakpointsValues: BreakpointsValues,
  ) => void;
};

const RouteContent = (props: RouteContentProps) => {
  const { breakpoints } = useTheme();
  const { isSignedIn, dispatchResizeWindow } = props;
  const [windowSize, setWindowSize] = useState<number[]>(getWindowSize());
  const containerRef = useRef<HTMLDivElement>(null);

  const handleWindowResize = () => {
    setWindowSize(getWindowSize());
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      const containerPadding = BODY_VERTICAL_PADDING * 2;
      const newHeight = containerRef?.current?.clientHeight
        ? containerRef.current.clientHeight - containerPadding
        : 0;
      dispatchResizeWindow(windowSize[0], newHeight, breakpoints.values);
    }
  }, [isSignedIn, windowSize]);

  if (isSignedIn) {
    return (
      <ContentStyle ref={containerRef}>
        <RouteMap routes={flattenedRoutesList} />
      </ContentStyle>
    );
  }

  return (
    <LoginStyle>
      <RouteMap routes={flattenedRoutesList} />
    </LoginStyle>
  );
};

const mapStateToProps = ({ session }) => ({
  isSignedIn: session.isSignedIn,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResizeWindow: (
    newWindowWidth: number,
    newHeight: number,
    breakpointsValues: BreakpointsValues,
  ) => dispatch(resizeWindow(newWindowWidth, newHeight, breakpointsValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteContent);
